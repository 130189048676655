/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import firebase from '../../firebase.config';
import './maps.css';
import AddPackage from './AddPackage'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Badge,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  UncontrolledTooltip,
  Dropdown,
  CustomInput
} from "reactstrap";
import axios from 'axios';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
// core components
import Header from "components/Headers/Header.js";
import ReportPost from "./ReportPost";
import Modal from 'react-modal';
import ViewPayments from "./ViewPayments";
// mapTypeId={google.maps.MapTypeId.ROADMAP}

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      borderRadius          : 15,
      width                 : '25%'
    },
    overlay: {
        backgroundColor: 'rgb(0,0,0,0.4)'
    }
  };

  Modal.setAppElement('#root')

class PaymentHistory extends React.Component {
  constructor(props){
    super(props);
    this.state={
  
      addPackage: false,
      loading: true,
      isEdit: false,
      editData: '',
      deleteData: '',
      deleteModal: false,
      package: [],
      viewPayment: ''
    }
  }
  componentDidMount(){

    var userId = localStorage.getItem('uid');
    console.log("userId", userId)
      
      firebase.firestore().collection("payments").where("uid", "==", userId).onSnapshot((res) => {
          var mydoc = []
          if(res.docs.length != 0){
        res.forEach((data) => {
            console.log("data", data.data())

            if(data){
                firebase.firestore().collection("packages").doc(data.data().packageId).get()
                .then((pack) => {
                    console.log("package", pack.data())
                    mydoc.push({payment: data.data(), package: pack.data()})

                    this.setState({
                        package: mydoc,
                        loading: false
                    })
                })
            }

        })
      }
      else{
        this.setState({
          loading: false
        })
      }

      })
   
  }

  

  addPackage = () => {
      this.setState({
          addPackage: true,
          editData: ""
      })
  }
 
  

selectedPackage (e, data) {
  e.preventDefault();
console.log("data", data)
this.setState({
  viewPayment: data,
    isEdit: true,
})
}


deleteModalOpen (e, data) {
    e.preventDefault();

    console.log(data)

    this.setState({
        deleteModal: true,
        deleteData: data
    })
}

goBack = () => {
  this.setState({
    isEdit: false,
  })
}

  render() {
    return (
      <>
        <Header />

        <Container className="mt--7" fluid>
        {this.state.isEdit ?
          <ViewPayments back={this.goBack} data={this.state.viewPayment} />
:
        <Card className="shadow">
                        <CardHeader className="border-0">
                            <h3 className="mb-0">Payment History</h3>
                        </CardHeader>
                        {this.state.loading ?
                        <div style={{display:'flex', justifyContent:'center', margin:10}}>
                          <Loader
                          type="TailSpin"
                          color="#A52C2C"
                          height={40}
                          width={40}
                         //  timeout={3000} //3 secs
                  
                       />
                       </div>
                       :
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Image</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Created on</th>
                                    <th scope="col">View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.package.map((data, index) => {
                                    console.log("see", data)
                                    return(
                                        <tr key={index}>
                                    <th scope="row">
                                        <Media className="align-items-center">
                                          
                                            <Media>
                                                <span className="mb-0 text-sm">
                                                    <img src={data.package.image ? data.package.image : require('assets/img/brand/noimg.jpg')} style={{width:50}} />                       </span>
                                            </Media>
                                        </Media>
                                    </th>
                                    <td >
                                        <p style={{textAlign:'center', width: 45}} color="" className="badge-dot">   
                                        {data.package.title}
                                        </p>
                                    </td>
                                  
                                    <td>
                                    <p style={{textAlign:'center', width: 45}} color="" className="badge-dot">   
                                        ${data.payment.amount}
                                        </p>
                                    </td>

                                    <td>
                                    <p style={{textAlign:'center', width: 45}} color="" className="badge-dot">   
                                        {new Date(data.payment.createdOn).getDate() + "/" + (new Date(data.payment.createdOn).getMonth() + 1) + "/" + new Date(data.payment.createdOn).getFullYear()}
                                        </p>
                                    </td>

                                    <td>
                                        <i style={{fontSize: 20, marginLeft:3, cursor:'pointer'}} onClick={(e) => this.selectedPackage(e,data)}  className="fas fa-eye"></i>
                                    </td>
                                  
                                </tr>
                                    );
                                  
                                })}

                            </tbody>
                        </Table>
                }
                    </Card>
  }
        </Container>
      </>
    );
  }
}

export default PaymentHistory;
