/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import firebase from "./../../firebase.config";
import { Redirect, useHistory } from "react-router-dom";
import './login.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import axios from 'axios'
import { serverUrl } from "./constant";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    toAdminDashboard: false,
    toClientDashboard: false,
    toUserDashboard: false,
    loading: false
  };


  handleChange(event, key) {
    this.setState({ [key]: event.target.value });
  }
  login() {
    const { email, password } = this.state;

    this.setState({
      loading: true
    })

    firebase.auth().signInWithEmailAndPassword(email, password).then(res => {
      console.log("user", res.user);
      console.log(res.user.uid);


      firebase.firestore().collection("admin").doc(res.user.uid).get().then((querySnapshot) => {
        console.log("check", querySnapshot)
       console.log("check", querySnapshot.exists)

       if(querySnapshot.exists == true){
    
        localStorage.setItem("status", 'Yes')

            var apiData = {
              "uid": res.user.uid
            }

            axios({
              url: serverUrl + "login",
              method: "post",
              data: apiData,
              withCredentials: true,
              responseType:"json"
            })
            .then((login) => {
              console.log("response:", login)
              console.log("token", login.data.result.token)
              localStorage.setItem("token", login.data.result.token)
              this.setState({
                loading: false,
                toAdminDashboard: true,
              })
        
            })
            .catch((err) => {
              console.log("error", err)
              console.log("error", err.response)
              this.setState({
                loading: false
              })

            })

          }
        else{
          alert("Only Admins can login Thankyou!");
          this.setState({
            loading: false
          })
        }

      });
    


    }).catch( (error) => {
      console.log(error.message)
      alert("Please enter valid email address or password for Admin");
      this.setState({
        loading: false
      })
    });
  }

  mypage = () => {
    return <Redirect to="/admin/index" />
  }

  render() {

    if (this.state.toAdminDashboard === true || localStorage.getItem("token")) {
      return <Redirect to="/admin/index" />;
    }
    
    // else if (this.state.toClientDashboard === true) {
    //   return <Redirect to="/client/projects" />;
    // }
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary loginBox border-0">
            {/* <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
            </CardHeader> */}
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input placeholder="Email" type="email" autoComplete="new-email"
                      onChange={val => {
                        this.handleChange(val, "email");
                      }}

                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password" autoComplete="new-password"
                      onChange={val => {
                        this.handleChange(val, "password");
                      }}

                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button style={{height:50, width:100}} className="my-4 buttonBg" color="primary" type="button"
                    onClick={() => {
                      this.login();
                    }}
                  >
                    {this.state.loading ?
                     <Loader
                     type="TailSpin"
                     color="#fff"
                     height={25}
                     width={25}
                    //  timeout={3000} //3 secs
             
                  />
                  :
                    "Sign in"
                }
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default Login;
