/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Badge,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    UncontrolledTooltip,
    Dropdown
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header.js";
import firebase from '../../firebase.config';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import axios from 'axios'
import './language.css'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      borderRadius          : 15,
      width                 : '25%'
    }
  };

  Modal.setAppElement('#root')

  var specialObje = {createPage: null, email: null, forgotPasword: null, languageDetails: {fullName: 'English', shortName: 'en'}, login: null, noAccount: null, password: null, signUp: null, nickname: null, name: null, timeOfPost: null, caption:null, commentTitle: null, following: null, follower: null, notifications: null, newComments: null, and: null, otherPeople: null, latestPost: null, youAreFollowing: null, directFollow: null, search: null, message: null, day: null, group: null, numberOfUnreadMessages: null, lastTimeOnline: null, messageText: null, info: null, media: null, files: null, audios: null, links: null,firstname: null,
  lastname: null,
  birthday: null,
  phone: null,
  address: null,
  sendForm: null,
  connectProfile: null,
  searchPerson: null,
  enableNotifications: null,
  mute: null,
  mute1Hr: null,
  mute2Hr: null,
  mute1Day: null,
  mute1Week: null,
  members: null,
  addMembers: null,
  edit: null,
  searchMembers: null,
  administrator: null,
  pageConnection: null,
  leaveGroup: null,
  deleteGroup: null,
  administrators: null,
  addAdmin: null,
  searchAdministrator: null,
  addAdministrator: null,
  connectPage: null,
  searchPosition: null,
  addServicePositions: null,
  sound: null,
  imaging: null,
  stream: null,
  disconnectPage: null,
  addServicePosition: null,
  program: null,
  tech: null,
  livestream: null,
  editInfo: null,
  newChat: null,
  whoShouldBe: null,
  newGroup: null,
  groupPhoto: null,
  privateVisibility: null,
  public: null,
  everyonePost: null,
  private: null,
  onlyPost: null,
  visible: null,
  everyoneGroup: null,
  notVisible: null,
  onlyGroup: null,
  inviteFriends: null,
  settings: null,
  notification: null,
  privacySecurity: null,
  paymentMethods: null,
  info: null,
  billingPlan: null,
  language: null,
  shareApp: null,
  deletePage: null,
  logout: null,
  privateChats: null,
  groups: null,
  likes: null,
  comments: null,
  newFollowers: null,
  acceptedRequests: null,
  allowComments: null,
  everyone: null,
  onlyFollowers: null,
  onlyFollow: null,
  followersAndFollow: null,
  accountPrivacy: null,
  everyoneFollow: null,
  acceptPeople: null,
  oldPassword: null,
  newPassword: null,
  repeatNewPassword: null,
  forgotPassword: null,
  privacyPolicy: null,
  company: null,
  termsOfUse: null,
  pleaseEnterInformation: null,
  visibleOrganizers: null,
  validInformation: null,
  letsOrganize: null,
  calendar: null,
  month: null,
  list: null,
  newEvent: null,
  absence: null,
  title: null,
  section: null,
  allDay: null,
  once: null,
  hiddenEvent: null,
  visibleParticipants: null,
  notes: null,
  holidays: null,
  illness: null,
  absent: null,
  work: null,
  editEvent: null,
  eventName: null,
  deleteSingle: null,
  deleteFuture: null,
  daily: null,
  weekly: null,
  monthly: null,
  yearly: null,
  event: null,
  agenda: null,
  services: null,
  decline: null,
  writeSomething: null,
  before: null,
  beginning: null,
  item: null,
  title: null,
  duration: null,
  description: null,
  person: null,
  titleName: null,
  itemName: null,
  person: null,
  audioVisual: null,
  agendaTemplates: null,
  template: null,
  templateTitle: null,
  searchContacts: null,
  searchTemplates: null,
  editItem: null,
  during: null,
  before: null,
  after: null,
  services: null,
  addPerson: null,
  filterPosition: null,
  singing: null,
  guitar: null,
  piano: null,
  whichPosition: null,
  profiles: null,
  groups: null,
  roles: null,
  people: null,
  newProfile: null,
  connectAccount: null,
  pageSocial: null,
  events: null,
  people: null,
  resources: null,
  allPageSocial: null,
  createPosts: null,
  sendMessages: null,
  likePosts: null,
  makeComments: null,
  pageCalendar: null,
  allPageCalendar: null,
  pageEvents: null,
  allPageEvents: null,
  createEvents: null,
  editEvents: null,
  deleteEvents: null,
  viewAgenda: null,
  editAgenda: null,
  createAgendaTemplate: null,
  editAgendaTemplate: null,
  deleteAgendaTemplate: null,
  otherFunctions: null,
  sendRequests: null,
  blockRequests: null,
  pageServices: null,
  allPageServices: null,
  viewServices: null,
  createServices: null,
  editServices: null,
  deleteServices: null,
  servicePositions: null,
  viewPositions: null,
  createPositions: null,
  editPositions: null,
  deletePositions: null,
  pagePeople: null,
  allPagePeople: null,
  viewProfiles: null,
  createProfiles: null,
  editProfiles: null,
  deleteProfiles: null,
  groups: null,
  viewGroups: null,
  createGroups: null,
  editGroups: null,
  deleteGroups: null,
  viewNotes: null,
  createNotes: null,
  editNotes: null,
  deleteNotes: null,
  newUser: null,
  admin: null,
  music: null,
  technicians: null,
  kitchenStaff: null,
  pcManagement: null,
  infrastructure: null,
  coordinator: null,
  ok: null,
  cancel: null,
  error: null,
  success: null,
  cameraPermissionTitle: null,
  cameraPermissionMessage: null,
  audioPermissionTitle: null,
  audioPermissionMessage: null,
  addService: null,
  serviceTitle: null,
  invalidTitleAlert: null,
  templateTitleAlert: null,
  itemSectionAlert: null,
  passwordChangedAlert: null,
  passwordDidntChangedAlert: null,
  enterOldPasswordAlert: null,
  recoveryEmailAlert: null,
  recoveryEmailErrorAlert: null,
  sharePost: null,
  opppa: null,
  newaccount: null,
  heya: null,
  gapi: null,
  selectPosition: null,
  searchPositionAddPerson: null,
  willi: null,
  changePasswordErrorTitle: null,
  changePasswordError1: null,
  changePasswordError2: null,
  changePasswordError3: null,
  cannotRespond: null,
  selectDocument: null,
  selectImage: null,
  deleteChatView: null,
  commentReplies: null,
  commentPost: null,
  fillForm: null,
  confirmationDelete: null,
  sureDelete: null,
  noDelete: null,
  yesDelete: null,
  dateNote: null,
  updateNote: null,
  statusAll: null,
  statusCurrent: null,
  editBefore: null,
  editDuring: null,
  editAfter: null,
  editServiceTitle: null,
  editServiceBody: null,
  drawerTitle: null,
  inputSearch: null,
  unfollowConfirmation: null,
  unfollowNote: null,
  unfollowRemove: null,
  forgotTitle: null,
  forgotBody: null,
  forgotError: null,
  errorBody: null,
  sendMail: null,
  multipleError: null,
  multipleError2: null,
  selectError: null,
  headerTtitle: null,
  headerBottom: null,
  groupBody: null,
  removeText: null,
  removeName: null,
  warningLeave: null,
  warningChat: null,
  followAccounts: null,
  infoHeaderBottom: null,
  infoNick: null,
  inviteError: null,
  createSection: null,
  itemNameEnter: null,
  setionError: null,
  requiredError: null,
  passwordStrength: null,
  emailInvalid: null,
  incorectEmail: null,
  userExist: null,
  validDetail: null,
  enterTitle: null,
  privateVisible: null,
  chatPublic: null,
  privacyPublic: null,
  chatPrivate: null,
  privacyPrivate: null,
  visibleTo: null,
  groupFind: null,
  visiblePrivacy: null,
  fillDetails: null,
  updatingError: null,
  organizerEvent: null,
  organizerAbsence: null,
  requestSent: null,
  dataError: null,
  feature: null,
  modifyRoles: null,
  errorOccoured: null,
  rolesError: null,
  screenFirstName: null,
  screenReason: null,
  screenHolidays: null,
  screenNotes: null,
  screenVacations: null,
  searchName: null,
  groupName: null,
  pageName: null,
  sentButton: null,
  sendButton: null,
  serviceAddTitle: null,
  serviceInput: null,
  serviceTask: null,
  deletePositionWarn: null,
  deleteServiceWarn: null,
  alreadyExist: null,
  wentWrong: null,
  checkConnection: null,
  createAccount: null,
  signUpEmail: null,
  sectionName: null,
  sectionError: null,
  younectTitle: null,
  privleges: null,
  home: null,
  organizor: null,
  sharingYounect: null,
  followProfile: null,
  unFollowProfile: null,
  drawerPages: null,

}

class LanguageSelected extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            dropdownOpen: false,
            language: 'English',
            languageSymbol: 'en',
            languageData: [],
            modalIsOpen: false,
            value: '',
            data:this.props.data
        }
    }

    componentDidMount() {


      firebase.firestore().collection("languages").get().then((querySnapshot) => {

        var data = this.state.languageData;
        
        querySnapshot.forEach((doc) => {
            var alldata = doc.data();
            console.log("Languages:", doc.data())
            data.push(alldata);
            // this.state.languageData.push(alldata)

        });

        this.setState({
            languageData: data
        })

      });

      console.log("check selected:", this.props.data)

      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));


    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    createLanguage (e) {
        e.preventDefault();

        var check = false;

        console.log("check Languge here:", this.state.language, this.state.languageSymbol);

        firebase.firestore().collection("languages")
        .get().then((data) =>{

            data.forEach((doc) =>{
                console.log("All Languages:", doc.data());
                var myData = doc.data().languageDetails;

                console.log(myData.fullName)

                if(myData.fullName == this.state.language){
                    check = true;
                }

            })

            console.log("status", check)

            if(check == false){
                
                 firebase.firestore().collection("languages").doc(this.state.languageSymbol)
                .set({createPage: 'Create a Page!', email: 'E-Mail', forgotPasword: 'Forgot Password?', languageDetails: {fullName: this.state.language, shortName: this.state.languageSymbol}, login: 'Login', noAccount: "Don't have an account?", password: 'Password', signUp: 'Sign Up!', nickname: 'Page Name', name: 'Usama Moin', timeOfPost: '11:04', caption:' Dieses Eis war das letzte seiner Art. Es verschied mit Würdeund... ▼', commentTitle: 'Comment', following: 'Following', follower: 'Followers', notifications: 'Notifications', newComments: 'posted a new comment on your post.', and: 'and', otherPeople: 'other people follow you now', latestPost: 'likes your post.', youAreFollowing: 'accepted your follow request', directFollow: 'is now following you', search: 'Search', message: 'Hey mein Freud, wie geht es dir? Ich', day: 'Wed', group: 'Group 1', numberOfUnreadMessages: '4', lastTimeOnline: 'last time online', messageText: 'message', info: 'Info', media: 'Media', files: 'Files', audios: 'Audios', links: 'Links',firstname: 'First name',
                lastname: 'Last name',
                birthday: 'Birthday',
                phone: 'Phone',
                address: 'Address',
                sendForm: 'Send this form to ',
                connectProfile: 'connect profile with account',
                searchPerson: 'Search person',
                enableNotifications: 'Enable Notifications',
                mute: 'Mute',
                mute1Hr: 'Mute for 1 hour',
                mute2Hr: 'Mute for 2 hour',
                mute1Day: 'Mute for 1 Day',
                mute1Week: 'Mute for 1 week',
                members: 'Members',
                addMembers: 'Add Members',
                edit: 'Edit',
                searchMembers: 'Search Members',
                administrator: 'Administrator',
                pageConnection: 'Page Connection',
                leaveGroup: 'Leave Group',
                deleteGroup: 'Delete Group',
                administrators: 'Administrators',
                addAdmin: 'add admin',
                searchAdministrator: 'Search Administrator',
                addAdministrator: 'Add Administrator',
                connectPage: 'Connect with Page',
                searchPosition: 'Search Page',
                addServicePositions: 'add service positions',
                sound: 'Sound',
                imaging: 'Imaging',
                stream: 'Stream',
                disconnectPage: 'Disconnect Page',
                addServicePosition: 'Add Position',
                program: 'Program',
                tech: 'Tech',
                livestream: 'Livestream',
                editInfo: 'Edit Info',
                newChat: 'New Chat',
                whoShouldBe: 'Search Users',
                newGroup: 'New Group',
                groupPhoto: 'Group Photo',
                privateVisibility: 'Private and Visibility',
                public: 'Public',
                everyonePost: 'Everyone can see members and posts',
                private: 'Private',
                onlyPost: 'Only members can see members and posts',
                visible: 'Visible',
                everyoneGroup: 'Everyone can find this group',
                notVisible: 'Not visible',
                onlyGroup: 'Only members can find this group',
                inviteFriends: 'Invite Friends',
                settings: 'Settings',
                notification: 'Notification',
                privacySecurity: 'Privacy and Security',
                paymentMethods: 'Payment Methods',
                info: 'Info',
                billingPlan: 'Billing Plan',
                language: 'Language',
                shareApp: 'Share younect app',
                deletePage: 'Delete Page',
                logout: 'Logout',
                privateChats: 'Private Chats',
                groups: 'Groups',
                likes: 'Likes',
                comments: 'Comments',
                newFollowers: 'New Followers',
                acceptedRequests: 'Accepted Requests',
                allowComments: 'Allow Comments',
                everyone: 'Everyone',
                onlyFollowers: 'Only your Followers',
                onlyFollow: 'Only whom you follow',
                followersAndFollow: 'Followers and whom you follow',
                accountPrivacy: 'Account Privacy',
                everyoneFollow: 'Everyone can follow you and see your posts',
                acceptPeople: 'Accept people`s requests before they see your posts',
                oldPassword: 'Old password',
                newPassword: 'New password',
                repeatNewPassword: 'Repeat new password',
                forgotPassword: 'Forgot Password?',
                privacyPolicy: 'Privacy Policy',
                company: 'Company',
                termsOfUse: 'Terms of Use',
                pleaseEnterInformation: 'Please enter your information',
                visibleOrganizers: 'Only visible for organizers',
                validInformation: 'Please enter valid information',
                letsOrganize: 'Let`s organize!',
                calendar: 'Calendar',
                month: 'Month',
                list: 'List',
                newEvent: 'New Event',
                absence: 'Absence',
                title: 'Title',
                section: 'Section',
                allDay: 'All day',
                once: 'Once',
                hiddenEvent: 'Hidden Event',
                visibleParticipants: 'visible only for participants',
                notes: 'Notes',
                holidays: 'Holidays',
                illness: 'Illness',
                absent: 'Absent',
                work: 'Work',
                editEvent: 'Edit Event',
                eventName: 'Event name',
                deleteSingle: 'Delete single occurrence',
                deleteFuture: 'Delete all future occurrence',
                daily: 'Daily',
                weekly: 'Weekly',
                monthly: 'Monthly',
                yearly: 'Yearly',
                event: 'Event',
                agenda: 'Agenda',
                services: 'Services',
                decline: 'Decline?',
                writeSomething: 'Notes',
                before: 'Before',
                beginning: 'Beginning',
                item: 'Item',
                title: 'Title',
                duration: 'Duration',
                description: 'Description',
                person: 'Person',
                titleName: 'Title Name',
                itemName: 'Item Name',
                person: 'Person',
                audioVisual: 'Audio/Visual',
                agendaTemplates: 'Templates',
                template: 'Template',
                templateTitle: 'Template Title',
                searchContacts: 'Search contacts',
                searchTemplates: 'Search templates',
                editItem: 'Edit Item',
                during: 'during',
                before: 'Before',
                after: 'after',
                services: 'Services',
                addPerson: 'Add Person',
                filterPosition: 'Filter by Position',
                singing: 'Singing',
                guitar: 'Guitar',
                piano: 'Piano',
                whichPosition: 'Which Position?',
                profiles: 'Profiles',
                groups: 'Groups',
                roles: 'Roles',
                people: 'People',
                newProfile: 'New Profile',
                connectAccount: 'Connect with an existing account',
                pageSocial: 'Page Social Interactions',
                events: 'Events',
                people: 'People',
                resources: 'Resources',
                allPageSocial: 'All Page Social Interactions',
                createPosts: 'create posts',
                sendMessages: 'send messages',
                likePosts: 'like posts',
                makeComments: 'make comments',
                pageCalendar: 'Page Calendar',
                allPageCalendar: 'All page calendar',
                pageEvents: 'Page Events',
                allPageEvents: 'All page events',
                createEvents: 'create events',
                editEvents: 'edit events',
                deleteEvents: 'delete events',
                viewAgenda: 'view agenda',
                editAgenda: 'edit agenda',
                createAgendaTemplate: 'create agenda template',
                editAgendaTemplate: 'edit agenda template',
                deleteAgendaTemplate: 'delete agenda template',
                otherFunctions: 'Other Functions',
                sendRequests: 'send requests',
                blockRequests: 'block requests',
                pageServices: 'Page Services',
                allPageServices: 'All page services',
                viewServices: 'view services',
                createServices: 'create services',
                editServices: 'edit services',
                deleteServices: 'delete services',
                servicePositions: 'Service Positions',
                viewPositions: 'view positions',
                createPositions: 'create positions',
                editPositions: 'edit positions',
                deletePositions: 'delete positions',
                pagePeople: 'Page People',
                allPagePeople: 'All page people',
                viewProfiles: 'view profiles',
                createProfiles: 'create profiles',
                editProfiles: 'edit profiles',
                deleteProfiles: 'delete profiles',
                groups: 'Groups',
                viewGroups: 'view groups',
                createGroups: 'create groups',
                editGroups: 'edit groups',
                deleteGroups: 'delete groups',
                viewNotes: 'view notes',
                createNotes: 'create notes',
                editNotes: 'edit notes',
                deleteNotes: 'delete notes',
                newUser: 'New User',
                admin: 'Admin',
                music: 'Music',
                technicians: 'Technicians',
                kitchenStaff: 'Kitchen Staff',
                pcManagement: 'PC-Management',
                infrastructure: 'Infrastructure',
                coordinator: 'Coordinator',
                ok: 'Ok',
                cancel: 'Cancel',
                error: 'Error',
                success: ' Success',
                cameraPermissionTitle: 'Permission to use camera',
                cameraPermissionMessage: 'We need your permission to use your camera',
                audioPermissionTitle: 'Permission to use audio recording',
                audioPermissionMessage: 'We need your permission to use your audio',
                addService: 'Add Service',
                serviceTitle: 'Service Title',
                invalidTitleAlert: 'Please enter valid title',
                templateTitleAlert: 'Please enter template title',
                itemSectionAlert: 'Please add Items or Sections',
                passwordChangedAlert: 'Your password has been changed successfully.',
                passwordDidntChangedAlert: 'There was an error changing your password, please try again.',
                enterOldPasswordAlert: 'Please enter a valid old Password.',
                recoveryEmailAlert: 'Password recovery email has been sent to you successfully.',
                recoveryEmailErrorAlert: 'There was an error sending password recovery email.'
    
            })
                .then((res) => {
                    console.log(res)
                    console.log("language added!")
                })
                .catch((error) => {
                    console.log("Add error:", error)
                })
            }
            else{
                console.log("Oops already added")
                this.openModal();
            }

        })
    }


   async translate (e) {
        e.preventDefault();

        var dataObj;
        var list = "";

     var fbData = await firebase.firestore().collection("languages").doc('en').get()
        .then((res) => {
            
            console.log(res.data())

            dataObj = res.data();

            Object.entries(dataObj).sort().map((response) => {

                if(response[0] != 'languageDetails'){
              
                console.log(response[1])

                list = list + response[1];
                list = list + '#@'
                }

            })

        })

        console.log("list:", list)


        var data = list;

        var myData = data.split('#@')
        console.log(myData)

        myData.map((data) => {
            console.log(data)
        })

        var createPage = myData[0];
        var email = myData[1];
        var forgotPassword = myData[2];
        var login = myData[3];
        var dontAccount = myData[4];
        var password = myData[5];
        var signUp = myData[6];

        console.log(createPage, email, forgotPassword, login, dontAccount, password, signUp)

        // var data = {
        //     "source": "en",
        //     "q": "Hello, world!",
        //     "target": "es"
        // }

        // axios({
        //     method: 'post',
        //     url: 'https://google-translate1.p.rapidapi.com/language/translate/v2',
        //     data: data,
        //     headers: {
        //         "x-rapidapi-host": "google-translate1.p.rapidapi.com",
        //         "x-rapidapi-key": "56dbfe09f1msh816930f6d0f5e1ep114452jsn68124774fac4",
        //         // "accept-encoding": "application/gzip",
        //         "content-type": "application/x-www-form-urlencoded",
        //         "useQueryString": true
        //     }
        // })
        // .then((res) => {
        //     console.log("translation:", res)
        // })
        // .catch((err) => {
        //     console.log(err)
        // })

    }

    openModal = () => {
       this.setState({
           modalIsOpen: true
       })
      }

       closeModal = () => {
        this.setState({
            modalIsOpen: false
        })
      }

      changeParam (text, key) {
        var data = {...this.props.constant, ...this.state.data};
        data[key] = text.target.value;

        // console.log("data",text.key.data);

        this.setState({
            data: data
        })

      }

      async updateLanguage (e, value) {
          e.preventDefault();
          
          console.log("here it is",value);
          
          var myKey = value;

          var data = {...this.props.constant,...this.state.data}

          console.log("value here:", data[value])
        console.log(this.state.data);
        console.log(this.state.data.languageDetails.shortName);
        var code = this.state.data.languageDetails.shortName;

          var fireData = await firebase.firestore().collection("languages").doc(code)
          .update(
              {
                  [myKey] :data[value]
              }
          )
          .then((res) => {
            console.log("lan updated:");
            // this.props.closeLang();
            firebase.firestore().collection("languageDetails").doc("time").set({timestamp: new Date().getTime()})
            .then((data) => {
              console.log("Time Updated")
            });

            NotificationManager.success('Updated Successfully', 'Translation', 1000, () => {

            });
          })
          .catch((err) => {
            console.log(err);

            
          NotificationManager.error('Unable to update', 'Error', 2000, () => {
            // alert('callback');
          });

          })
      }


      async saveLanguage () {

        console.log(this.state.data);
        console.log(this.state.data.languageDetails.shortName);
        var code = this.state.data.languageDetails.shortName;

          var fireData = firebase.firestore().collection("languages").doc(code)
          .update(this.state.data)
          .then((res) => {
            console.log("lan updated:", res);

             // this.props.closeLang();
             firebase.firestore().collection("languageDetails").doc("time").set({timestamp: new Date().getTime()})
             .then((data) => {
               console.log("Time Updated")
             });

            NotificationManager.success('Updated Successfully', 'Translation', 1000, () => {

            });


            setTimeout(() => {
                this.props.closeLang();
            }, 700);

          })
          .catch((err) => {
            console.log(err);
            NotificationManager.error('Unable to update', 'Error', 2000, () => {
                // alert('callback');
              });
          })
      }

    render() {
        
        return (
            <>
                {/* <Header /> */}

                <Modal
          isOpen={this.state.modalIsOpen}
        //   onAfterOpen={afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
            <div style={{width: '100%', alignItems:'center'}}>
                <p onClick={this.closeModal} style={{textAlign:'right', fontWeight: 'bold'}}>X</p>
            <div style={{marginTop: 20, marginBottom: 40}}>
          <h3>Oops this language is already added!</h3>
          </div>
          </div>
       
        </Modal>

                {/* Page content */}
               
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            {/* <h2 className="mb-0">{this.props.data.languageDetails.fullName} </h2> */}
                        </CardHeader>

                        {Object.entries(this.props.constant).sort().map((res, index) =>{
                            if(res[0] != "languageDetails"){
                            return(
                                <div style={{padding: 10}} key={index}>
                                <div className="row" style={{marginBottom: 20}}>
    
                                <div className="col-sm-3 col-md-4">
                                    <h4 style={{paddingLeft:20, }}> {res[0]} </h4>
                                </div>
    
                                <div className="col-sm-3 col-md-4">
                                <Input type='text' name={res[0]}  onChange={(text) => this.changeParam(text, res[0])}   style={{width: 300}}  placeholder={""} defaultValue={this.props.data[res[0]]?this.props.data[res[0]]:res[1]}  />
                                </div>

                                <div className="col-sm-3 col-md-4">
                                <Col className="text-right" xs="4">
                                            <Button
                                                className="lang-button"
                                                color="primary"
                                                href="#pablo"
                                                onClick={(e) => this.updateLanguage (e, res[0])}
                                                size="md"
                                            >
                                                Update
                      </Button>
                                        </Col>
                                </div>
    
                                </div>
                            </div>
                            );
                            }
                        })}

                        <div style={{textAlign:'center', marginBottom: 30}}>
                            <Button onClick={() => this.saveLanguage()}
                             style={{width: 200, backgroundColor: '#A52C2C', color: '#fff',
                              borderColor: '#A52C2C', fontWeight:'bold'}}>
                                  Save
                            </Button>
                        </div>
                       
                       
                        {/* <CardFooter className="py-4">
                            <nav aria-label="...">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem>
                                        <PaginationLink
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                                            <i className="fas fa-angle-right" />
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </nav>
                        </CardFooter> */}
                        <NotificationContainer enterTimeout={100} />
                    </Card>

                
            </>
        );
    }
}

export default LanguageSelected;
