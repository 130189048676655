/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Language from "views/examples/Language.js"
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import Packages from "views/examples/Packages";
import PromoCode from 'views/examples/PromoCode'
import BuyPackage from "views/examples/BuyPackage";
import PaymentHistory from "views/examples/PaymentHistory";
import PaymentsAdmin from "views/examples/PaymentAdmin";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-red",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Reports",
    icon: "fas fa-file text-red",
    component: Maps,
    layout: "/admin"
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/language",
    name: "Language",
    icon: "ni ni-single-02 text-red",
    component: Language,
    layout: "/admin"
  },

  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin"
  },
  {
    path: "/packages",
    name: "Packages",
    icon: "fas fa-comments-dollar text-red",
    component: Packages,
    layout: "/admin"
  },
  // {
  //   path: "/buypackage",
  //   name: "Subscription",
  //   icon: "fas fa-search-dollar text-red",
  //   component: BuyPackage,
  //   layout: "/admin"
  // },
  // {
  //   path: "/payment",
  //   name: "Payment History",
  //   icon: "fas fa-money-bill text-red",
  //   component: PaymentHistory,
  //   layout: "/admin"
  // },
  {
    path: "/paymentAdmin",
    name: "Payments",
    icon: "fas fa-money-bill text-red",
    component: PaymentsAdmin,
    layout: "/admin"
  },
  {
    path: "/promo",
    name: "Promo",
    icon: "fas fa-box-open text-red",
    component: PromoCode,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  },
 
];
export default routes;
