/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import firebase from '../../firebase.config';
import './maps.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Badge,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  UncontrolledTooltip,
  Dropdown,
  CustomInput
} from "reactstrap";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from 'axios'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

// core components
import Header from "components/Headers/Header.js";
import { serverUrl } from "./constant";
// mapTypeId={google.maps.MapTypeId.ROADMAP}

var curr;
var date;

var startCurr;
var startDate;
class AddPromoCode extends React.Component {
  constructor(props){
    super(props);
    this.state={
      posts:[],
      title: "",
      expiry: "",
      promo: "",
      image: "",
      imageUrl: '',
      description: "",
      discount: "",
      error: false,
      loading: false,
      showDate: '',
      createdOn: '',
      isActive: "",
      startDate: "",
      token : ''
    }
    
     
     curr = this.props.data.redeem_by ? new Date(this.props.data.redeem_by) : "";
    // curr.setDate(curr.getDate() + 3);
    date = this.props.data.redeem_by ? curr.toISOString().substr(0,10) : "";

    startCurr = this.props.data.created ? new Date(this.props.data.created) : "";
    startDate = this.props.data.created ? startCurr.toISOString().substr(0,10) : "";

  }
  componentDidMount(){
  
    if(this.props.data){
      console.log("edit here", date)
      this.setState({
       title: this.props.data.title,
       expiry: this.props.data.expiryDate,
       promo: this.props.data.promocode,
       discount: this.props.data.discount,
       image: this.props.data.image,
       description: this.props.data.description,
       createdOn: this.props.data.createdOn,
       isActive: this.props.data.isActive,
       startDate: this.props.data.startDate
      })
     }

     this.setState({
      token: localStorage.getItem("token")
    })
     
  }

  changeParam = (event) => {
      console.log("Input values", event.target.name, event.target.value)
    this.setState({
        [event.target.name]: event.target.value,
        // error: false
      });
  }

  changeImage = (event) => {
    console.log("Input values", event.target.name, event.target.files[0])
    console.log("Input values", URL.createObjectURL(event.target.files[0]))
  this.setState({
      [event.target.name]: event.target.files[0],
      imageUrl: URL.createObjectURL(event.target.files[0])
    });
}

sumbitCode = () => {
  this.setState({
    loading: true
  })
  
    if(this.state.title != "" && this.state.description != "" && this.state.expiry != "" && this.state.promo != "" && this.state.discount != "" && this.state.startDate != ""){
        
      var apiData = new FormData();
      apiData.append("title", this.state.title)
      apiData.append("expiryDate", new Date(this.state.expiry).getTime())
      apiData.append("startDate", new Date(this.state.startDate).getTime())
      apiData.append("promocode", this.state.promo)
      apiData.append("discount", this.state.discount)
      apiData.append("description", this.state.description)
      apiData.append("createdOn", new Date().getTime())
      if(this.state.image){
        apiData.append("image", this.state.image)
        }
    
      axios({
        url: serverUrl + "promocode/add",
        method: "post",
        data: apiData,
        withCredentials: true,
        headers:{
          Authorization: 'Bearer' + " " + this.state.token,
          'Content-Type': 'application/json'
        }
      })
      .then((res) => {
        console.log("response:", res)
        this.setState({
          title: "",
          expiry: "",
          promo: "",
          image: "",
          imageUrl: '',
          description: "",
          discount: "",
          loading: false
        })
        this.props.isSaved();
      })
      .catch((err) => {
        console.log("error", err)
        console.log("error", err.response)
        this.setState({
          loading: false
        })
      })
       
    }
    else{
        NotificationManager.error('Please fill the required fields.');
        this.setState({
            error: true,
            loading: false
        })
    }
}

editCode = () => {
  this.setState({
    loading: true
  })

  if(this.state.title != "" && this.state.description != "" && this.state.expiry != "" && this.state.promo != "" && this.state.discount != "" && this.state.startDate != ""){
        
    var apiData = new FormData();
    apiData.append("title", this.state.title)
    apiData.append("promocode", this.state.promo)
    apiData.append("discount", this.state.discount)
    apiData.append("description", this.state.description)
    apiData.append("createdOn", this.state.createdOn)
    apiData.append("key", this.props.data.key)
    apiData.append("isActive", this.state.isActive)
    if(this.state.image){
      apiData.append("image", this.state.image)
      }
      if(this.props.data && this.props.data.expiryDate != this.state.expiry){
        apiData.append("expiryDate", new Date(this.state.expiry).getTime())
      }
      else{
        apiData.append("expiryDate", this.state.expiry)
      }

      if(this.props.data && this.props.data.startDate != this.state.startDate){
        apiData.append("startDate", new Date(this.state.startDate).getTime())
      }
      else{
        apiData.append("startDate", this.state.startDate)
      }
  
    axios({
      url: serverUrl + "promocode/update",
      method: "post",
      data: apiData,
      withCredentials: true,
      headers:{
        Authorization: 'Bearer' + " " + this.state.token,
        'Content-Type': 'application/json'
      }
    })
    .then((res) => {
      console.log("response:", res)
      this.setState({
        loading: false
      })

      this.props.isSaved();
    })
    .catch((err) => {
      console.log("error", err)
      console.log("error", err.response)
      this.setState({
        loading: false
      })
    })
  
  }
  else{
      NotificationManager.error("Can't save empty fields.");
      this.setState({
          error: true,
          loading: false
      })
  }
}

  render() {
    return (
      <>
        {/* <Header /> */}
        {/* Page content */}
        <Container className="mt--7" fluid>
        <Card className="shadow">
                        <CardHeader className="border-0">
                          <div className="headRow">
                            
                            <h3 className="mb-0">Promo Code</h3>
                           
                            <div onClick={this.props.back} className="backButton">
                             <h4 className="deleteText">Back</h4>
                            </div>
                            </div>
                        </CardHeader>
                       <CardBody>
                           
                       <div style={{padding: 10}} >
                                <div className="row" style={{marginBottom: 20}}>
    
                                <div style={{display:'flex', alignItems:'center'}} className="col-sm-3 col-md-3">
                                    <h4 style={{paddingLeft:20, }}> Promo Code </h4>
                                </div>
    
                                <div className="col-sm-3 col-md-4">
                                <Input disabled defaultValue={this.props.data ? this.props.data.name : ""} className={this.state.error && this.state.title.length == 0 ? "inputStyle" : ""} type='text' name={"title"}  onChange={this.changeParam}   style={{width: 300}}  placeholder={""}  />
                                </div>

                              
    
                                </div>

                                <div className="row" style={{marginBottom: 20}}>
    
                                <div style={{display:'flex', alignItems:'center'}} className="col-sm-3 col-md-3">
                                    <h4 style={{paddingLeft:20, }}> Start Date </h4>
                                </div>

                                <div className="col-sm-3 col-md-4">
                                <Input disabled defaultValue={this.props.data ? startDate : ""} className={this.state.error && this.state.startDate.length == 0 ? "inputStyle" : ""} type='date'  name={"startDate"}  onChange={this.changeParam}   style={{width: 300}}  placeholder={""}  />
                                </div>

                                </div>

                                <div className="row" style={{marginBottom: 20}}>
    
                                    <div style={{display:'flex', alignItems:'center'}} className="col-sm-3 col-md-3">
                                        <h4 style={{paddingLeft:20, }}> Expiry </h4>
                                    </div>

                                    <div className="col-sm-3 col-md-4">
                                    <Input disabled defaultValue={this.props.data.duration == "once" ? this.props.data.duration : date} className={this.state.error && this.state.expiry.length == 0 ? "inputStyle" : ""} type={this.props.data.duration == 'once' ? 'text' : 'date'}  name={"expiry"}  onChange={this.changeParam}   style={{width: 300}}  placeholder={""}  />
                                    </div>

                                    </div>

                                    <div className="row" style={{marginBottom: 20}}>
                                    <div style={{display:'flex', alignItems:'center'}} className="col-sm-3 col-md-3">
                                        <h4 style={{paddingLeft:20, }}> Discount </h4>
                                    </div>

                                    <div className="col-sm-3 col-md-4">
                                    <Input disabled defaultValue={this.props.data.amount_off ? this.props.data.amount_off : this.props.data.percent_off + '%'} className={this.state.error && this.state.promo.length == 0 ? "inputStyle" : ""} type='text' name={"promo"}  onChange={this.changeParam}   style={{width: 300}}  placeholder={""}  />
                                    </div>
                                    </div>

  {/* Image Work temporary not in use */}

                                    {/* <div className="row" style={{marginBottom: 20}}>
    
                                        <div style={{display:'flex', alignItems:'center'}} className="col-sm-3 col-md-3">
                                            <h4 style={{paddingLeft:20, }}> Image </h4>
                                        </div>

                                        <div className="col-sm-3 col-md-4">
                                        <Input type='file' name={"image"}  onChange={this.changeImage}   style={{width: 300}}  placeholder={""}  />
                                        </div>

                                    

                                        </div>

                                        {this.state.image ?
                                        <div className="row" style={{marginBottom: 20}}>
                                        
                                        <div style={{display:'flex', alignItems:'center'}} className="col-sm-3 col-md-3">
                                        </div>

                                        <div className="col-sm-3 col-md-4">
                                        
                                        <img src={this.props.data ? this.props.data.image : this.state.imageUrl} style={{width: 200}} />
                                                                        
                                        </div>
                                         </div>
                                         : null } */}

                                     
                                        {/* <div className="row" style={{marginBottom: 20}}>
    
                                            <div style={{display:'flex', alignItems:'center'}} className="col-sm-3 col-md-3">
                                                <h4 style={{paddingLeft:20, }}> Description </h4>
                                            </div>

                                            <div className="col-sm-3 col-md-4">
                                            <Input rows={4} defaultValue={this.props.data ? this.props.data.description : ""} className={this.state.error && this.state.description.length == 0 ? "inputStyle" : ""} type='textarea' name={"description"}  onChange={this.changeParam}   style={{width: 300}}  placeholder={""}  />
                                            </div>

                                        

                                            </div> */}

                            </div>

                            {/* <div className="row" style={{marginBottom: 20, marginTop:20}}>
                            <div className="col-sm-3 col-md-3" />
                            <div  className="col-sm-3 col-md-4">
                            <Button onClick={this.props.data ? this.editCode : this.sumbitCode}
                             style={{width: 300, backgroundColor: '#A52C2C', color: '#fff',
                              borderColor: '#A52C2C', fontWeight:'bold', marginLeft: 5}}>
                                  {this.state.loading ?
                                 <Loader
                                 type="Oval"
                                 color="#fff"
                                 height={20}
                                 width={20}
                                //  timeout={3000} //3 secs
                         
                              />
                              :
                                  "Save"
                                }
                            </Button>
                            </div>
                        </div> */}
                            
                       </CardBody>
                       <NotificationContainer/>
                    </Card>
        </Container>
      </>
    );
  }
}

export default AddPromoCode;
