/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import firebase from '../../firebase.config';
import './maps.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Badge,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  UncontrolledTooltip,
  Dropdown,
  CustomInput
} from "reactstrap";

// core components
import Header from "components/Headers/Header.js";
import ReportPost from "./ReportPost";
// mapTypeId={google.maps.MapTypeId.ROADMAP}
const MapWrapper = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
      defaultOptions={{
        scrollwheel: false,
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [{ color: "#444444" }]
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [{ color: "#f2f2f2" }]
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [{ saturation: -100 }, { lightness: 45 }]
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [{ visibility: "simplified" }]
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [{ color: "#5e72e4" }, { visibility: "on" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
    </GoogleMap>
  ))
);

class Maps extends React.Component {
  constructor(props){
    super(props);
    this.state={
      reports:[],
      post: false,
      postData: ''
    }
  }
  componentDidMount(){
    var reports = [];
    firebase.firestore().collection("reports").get()
    .then((rep) => {
      rep.forEach((res) => {
        console.log("reports", res.data())
        // reports.push(res.data())
        firebase.firestore().collection("users").doc(res.data().reportedBy).get()
        .then((data) => {
          console.log("user", data.data())
        
          reports.push({...res.data(), user: data.data(), reportId: res.id})

          console.log("ff", reports)
          this.setState({
            reports: reports
          })
        })

      })
     
    })
  }

  showPost (data) {
    console.log("selected post", data)
    this.setState({
      post: true,
      postData: data
    })
  }

  goBack = () => {
    this.setState({
      post: false
    })
    this.componentDidMount()
  }

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {this.state.post ?
        <ReportPost delete={this.goBack} data={this.state.postData} />
        :  
        <Card className="shadow">
                        <CardHeader className="border-0">
                            <h3 className="mb-0">Reports</h3>
                        </CardHeader>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Reason</th>
                                    <th scope="col">Reported By</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">View</th>
                                    {/* <th scope="col">Delete</th> */}
                                   
                                    {/* <th scope="col" /> */}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.reports.map((data, index) => {
                                   if(data.isDeleted != true){
                                    return(
                                        <tr key={index}>
                                    <th scope="row">
                                        <Media className="align-items-center">
                                          
                                            <Media>
                                                <span className="mb-0 text-sm">
                                                    {data.reason}                            </span>
                                            </Media>
                                        </Media>
                                    </th>
                                    <td >
                                        <p style={{textAlign:'center', width: 45}} color="" className="badge-dot">   
                                        {data.user.email}
                                        </p>
                                    </td>
                                    <td >
                                        <p style={{textAlign:'center', width: 45}} color="" className="badge-dot">   
                                        {new Date(data.timestamp).getDate() + "/" + new Date(data.timestamp).getMonth() + "/" + new Date(data.timestamp).getFullYear()}
                                        </p>
                                    </td>
                                    <td>
                                        <div style={{marginLeft:5}} className="avatar-group view">
                                        <i style={{fontSize: 20}} onClick={() => this.showPost(data)}  className="fas fa-eye"></i>
                                        </div>
                                    </td>
                                    {/* <td>
                                    
                                    <label onClick={(e) => this.disabled(e, index, data)} className="custom-toggle custom-toggle-warning" >
                                    <input type="checkbox"  checked={data.languageDetails.disabled} />
                                    <span className="custom-toggle-slider rounded-circle " data-label-off="OFF" data-label-on="ON"></span>
                                </label>
                                    </td>
                                   <td>
                                   <i onClick={(e) => this.deleteModalOpen(e, data)} className="fas fa-trash deleteIcon"></i>
                                   </td> */}
                                    
                                </tr>
                                    );
                                  }
                                })}

                            </tbody>
                        </Table>
                      
                    </Card>
  }
        </Container>
      </>
    );
  }
}

export default Maps;
