/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Fragment } from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import firebase from "../../firebase.config";
import "./BuyPackage.css";
import AddPackage from "./AddPackage";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Badge,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  UncontrolledTooltip,
  Dropdown,
  CustomInput,
} from "reactstrap";
import axios from "axios";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
// core components
import Header from "components/Headers/Header.js";
import ReportPost from "./ReportPost";
import Modal from "react-modal";
import StripeContainer from "../../Stripe/StripeContainer";
import { serverUrl } from "./constant";

// mapTypeId={google.maps.MapTypeId.ROADMAP}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight           : '-50%',
    transform: "translate(-50%, -50%)",
    borderRadius: 15,
    width: "35%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  overlay: {
    backgroundColor: "rgb(0,0,0,0.4)",
  },
};

Modal.setAppElement("#root");

class BuyPackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      postData: "",
      addPackage: false,
      postData: [],
      loading: true,
      isEdit: false,
      editData: "",
      deleteData: "",
      deleteModal: false,
      showDescription: false,
      desIndex: null,
      dropdownOpen: false,
      listOfLang: [],
      promoCodes: [],
      selectedCode: "Monthly",
      codeIndex: null,
      discount: 0,
      discountDescription: "",
      subscribe: [],
      stripePromo: [],
      stripeUser: "",
      cardDetails: "",
      promoText: "",
      promoDiscount: 0
    };
  }
  componentDidMount() {
    firebase
      .firestore()
      .collection("packages")
      .onSnapshot((res) => {
        var posts = [];
        res.forEach((data) => {
          console.log("res", data.data());

          posts.push({ ...data.data(), key: data.id });
        });
        this.setState({
          postData: posts,
          loading: false,
        });
      });

    firebase
      .firestore()
      .collection("promocodes")
      .onSnapshot((res) => {
        var promo = [];
        var stripeCode = [];
        res.forEach((data) => {
          console.log("promo", data.data());

          promo.push({ ...data.data(), key: data.id });

          if (data.data() && data.data().title.toLowerCase() == "monthly") {
            stripeCode.push({ ...data.data(), key: data.id });
          }
        });
        this.setState({
          promoCodes: promo,
          stripePromo: stripeCode,
        });
      });

    console.log("uid", localStorage.getItem("uid"));

    var userId = localStorage.getItem("uid");

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .collection("subscriptions")
      .onSnapshot((sub) => {
        var subs = [];
        console.log("user subscription", sub.docs);
        sub.forEach((element) => {
          console.log("Element", element.data());
          subs.push(element.data());
        });
        this.setState({
          subscribe: subs,
        });
      });

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .onSnapshot((user) => {
        console.log("user", user.data());
        this.setState({
          stripeUser: user.data(),
        });

        if (user.data().stripePaymentMethodId) {
          var payId = {
            stripePaymentMethodId: user.data().stripePaymentMethodId,
          };

          axios({
            url: serverUrl + "subscription/card",
            method: "post",
            data: payId,
            headers: {
              Authorization: "Bearer" + " " + localStorage.getItem("token"),
            },
          })
            .then((response) => {
              console.log("subscribe Api", response);
              this.setState({
                cardDetails: response.data.result,
              });
            })
            .catch((err) => {
              console.log("error", err);
              console.log("error", err.response);
            });
        }
      });
  }

  showPost(data) {
    console.log("selected post", data);
    this.setState({
      post: true,
      postData: data,
    });
  }

  addPackage = () => {
    this.setState({
      addPackage: true,
    });
  };

  goBack = () => {
    this.setState({
      addPackage: false,
    });
  };

  selectedPackage(data) {
    console.log("data", data);
    this.setState({
      editData: data,
      isEdit: true,
      addPackage: true,
    });
  }

  savedPackage = () => {
    this.setState({
      addPackage: false,
      editData: "",
    });
  };

  deleteModalOpen(e, data, price) {
    e.preventDefault();

    console.log(data);

    console.log("price", parseFloat(price));

    console.log("promo code", this.state.stripePromo[0]);

    console.log("user stripe", this.state.stripeUser);
    if (this.state.cardDetails) {
      this.setState({
        deleteModal: true,
        deleteData: {
          ...data,
          discount: parseFloat(price),
          promocode: this.state.stripePromo[0],
          user: this.state.stripeUser,
          cardInfo: this.state.cardDetails,
        },
      });
    } else {
      this.setState({
        deleteModal: true,
        deleteData: {
          ...data,
          discount: parseFloat(price),
          promocode: this.state.stripePromo[0],
          user: this.state.stripeUser,
        },
      });
    }
  }

  deleteModalClose = () => {
    this.setState({
      deleteModal: false,
    });
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  changeCode(val) {
    console.log("data", val);

    var stripeCode = Array.from(this.state.stripePromo);

    stripeCode.splice(0, 1);
    stripeCode.push(val);

    this.setState({
      discount: val.discount,
      discountDescription: val.description,
      selectedCode: val.title,
      stripePromo: stripeCode,
      // codeIndex: data.index
    });
  }

  changePromo = (event) => {
    this.setState({
      promoText: event.target.value
    })
  }

  submitCode = () => {
    console.log("check code", this.state.promoText);
    firebase.firestore().collection("promocodes").where("promocode", "==", this.state.promoText).get()
    .then((data) => {
      console.log("data", data.docs)
      if(data.docs.length == 0){
        alert("Promocode doesn't exist!")
      }
      else{
      data.forEach((code) => {
        console.log("code", code.data())
        var element = code.data();
        if(element.expiryDate >= new Date().getTime()){
           if(element.isDeleted != true && element.isActive == true){
            console.log("validate success", element)
            this.setState({
              promoDiscount: element.discount
            });

            alert("Promocode added successfully!")
        }
        }
      })
    }
    })
  }

  render() {
    console.log("promodiscount",this.state.promoDiscount);
    return (
      <>
        <Header />
        {/* Page content */}

        <Modal
          isOpen={this.state.deleteModal}
          //   onAfterOpen={afterOpenModal}
          onRequestClose={this.deleteModalClose}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginTop: 20,
                marginBottom: 40,
                width: "30vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StripeContainer
                close={this.deleteModalClose}
                package={this.state.deleteData}
              />
            </div>
          </div>
        </Modal>

        <Container className="mt--7" fluid>
          <Card className="shadow">
            <CardHeader className="border-0">
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="row"
              >
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}} className="col-5">
                  <h3 className="mb-0">Packages</h3>

                  <Dropdown
                    style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggle}
                  >
                    <DropdownToggle caret>
                      {this.state.selectedCode}
                    </DropdownToggle>

                    <DropdownMenu className="dropMenuCode">
                      {this.state.promoCodes.map((element, index) => {
                        if (
                          element.title == "Monthly" ||
                          element.title == "Yearly"
                        )
                          return (
                            <DropdownItem
                              onClick={() => this.changeCode(element)}
                              key={index}
                            >
                              {element.title}
                            </DropdownItem>
                          );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}} className="col-7">
                <h3 className="mb-0">Add Promocode</h3>
                <input type='text' onChange={this.changePromo} style={{marginLeft:10, height:40}} />
                <div onClick={this.submitCode} style={{display:'flex', justifyContent:'center', marginLeft:10, width:100, height:40, marginTop:15}} className="button">ADD</div>
                </div>
              </div>
            </CardHeader>
            {this.state.loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: 10,
                }}
              >
                <Loader
                  type="TailSpin"
                  color="#A52C2C"
                  height={40}
                  width={40}
                  //  timeout={3000} //3 secs
                />
              </div>
            ) : (
              <div>
                <div className="row box">
                  {this.state.postData.map((res, index) => {
                    return (
                      <div className="col-4 " key={index}>
                        <div className="offerBox">
                          <h3>{res.title}</h3>
                          <img
                            className="AllimageStyle"
                            src={
                              res.image
                                ? res.image
                                : require("../../assets/img/brand/noimg.jpg")
                            }
                          />
                          {this.state.discountDescription ? (
                            <div style={{ marginTop: 10 }}>
                              <p style={{ marginTop: 5, textAlign: "center" }}>
                                {this.state.discountDescription}
                              </p>
                            </div>
                          ) : (
                            <div style={{ marginTop: 10 }}>
                              {this.state.showDescription &&
                              this.state.desIndex == index ? (
                                <p
                                  style={{ marginTop: 5, textAlign: "center" }}
                                >
                                  {res.description}
                                </p>
                              ) : (
                                <p
                                  style={{ marginTop: 5, textAlign: "center" }}
                                >
                                  {res.description.slice(0, 15)}...
                                  <span
                                    onClick={() =>
                                      this.setState({
                                        showDescription: true,
                                        desIndex: index,
                                      })
                                    }
                                    style={{
                                      color: "blue",
                                      fontSize: 14,
                                      cursor: "pointer",
                                    }}
                                  >
                                    See More
                                  </span>
                                </p>
                              )}
                              {this.state.showDescription &&
                              this.state.desIndex == index ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: 10,
                                  }}
                                >
                                  <span
                                    onClick={() =>
                                      this.setState({ showDescription: false })
                                    }
                                    style={{
                                      color: "blue",
                                      fontSize: 14,
                                      cursor: "pointer",
                                    }}
                                  >
                                    Hide
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          )}
                          <h4 className="peoples">
                            Number of Persons {res.numberOfPeople}
                          </h4>
                          <h1 className="price">
                            $
                            {this.state.selectedCode.toLowerCase() === "monthly"
                              ? (res.price - (res.price * this.state.promoDiscount/100))
                              : ((res.price - (res.price * this.state.promoDiscount/100)) - (res.price-(res.price * this.state.promoDiscount/100)) / 3).toFixed(2)}
                          </h1>
                          {this.state.subscribe.filter(
                            (a) => a.packageId == res.key
                          ).length > 0 ? (
                            <div className="button">UnSubscribe</div>
                          ) : (
                            <Fragment>
                              {this.state.subscribe.length != 0 ? (
                                <div className="disablebutton">Subscribe</div>
                              ) : (
                                <div
                                  onClick={(e) =>
                                    this.deleteModalOpen(
                                      e,
                                      res,
                                      this.state.selectedCode.toLowerCase() ===
                                        "monthly"
                                        ? (res.price - (res.price * this.state.promoDiscount/100))
                                        : ((res.price- (res.price * this.state.promoDiscount/100)) - (res.price-(res.price * this.state.promoDiscount/100)) / 3).toFixed(2)
                                    )
                                  }
                                  className="button"
                                >
                                  Subscribe
                                </div>
                              )}
                            </Fragment>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </Card>
        </Container>
      </>
    );
  }
}

export default BuyPackage;
