import firebase from "firebase/app";
import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyBAwWlf8TGRhl1eMvx53tgNiGBablNO7Mk",
    authDomain: "younect-bitrupt.firebaseapp.com",
    databaseURL: "https://younect-bitrupt.firebaseio.com",
    projectId: "younect-bitrupt",
    storageBucket: "younect-bitrupt.appspot.com",
    messagingSenderId: "157507959923",
    appId: "1:157507959923:web:6fb9921539163862763e06",
    measurementId: "G-5N79YEXWP6"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
