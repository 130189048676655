/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import firebase from '../../firebase.config';
import './maps.css';
import axios from 'axios';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Badge,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  UncontrolledTooltip,
  Dropdown,
  CustomInput
} from "reactstrap";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

// core components
import Header from "components/Headers/Header.js";
import { serverUrl } from "./constant";
// mapTypeId={google.maps.MapTypeId.ROADMAP}

class AddPackage extends React.Component {
  constructor(props){
    super(props);
    this.state={
      posts:[],
      title: "",
      people: "",
      price: "",
      image: "",
      description: "",
      error: false,
      imageUrl: "",
      loading: false,
      token: ""
    }
  }
  componentDidMount(){
    if(this.props.data){
     console.log("edit here", this.props.data)
     this.setState({
      title: this.props.data.title,
      people: this.props.data.numberOfPeople,
      price: this.props.data.price,
      image: this.props.data.image,
      description: this.props.data.description,
     })
    }
    this.setState({
      token: localStorage.getItem("token")
    })
  }

  changeParam = (event) => {
      console.log("Input values", event.target.name, event.target.value)
    this.setState({
        [event.target.name]: event.target.value,
        // error: false
      });
  }

  changeImage = (event) => {
    console.log("Input values", event.target.name, event.target.files[0])
  this.setState({
      [event.target.name]: event.target.files[0],
      imageUrl: URL.createObjectURL(event.target.files[0])
    });
}

sumbitPackage = () => {

  this.setState({
    loading: true
  })

  console.log("dddd", this.state.title, this.state.price, this.state.people, this.state.description, "image", this.state.image)

    if(this.state.title != "" && this.state.description != "" && this.state.people != "" && this.state.price != "" ){
        
      var apiData = new FormData();
      apiData.append("title", this.state.title)
      apiData.append("numberOfPeople", this.state.people)
      apiData.append("price", this.state.price)
      apiData.append("description", this.state.description)
      apiData.append("createdOn", new Date().getTime())
      if(this.state.image){
        apiData.append("image", this.state.image)
        }
    
      axios({
        url: serverUrl + "package/add",
        method: "post",
        data: apiData,
        withCredentials: true,
        headers:{
          Authorization: 'Bearer' + " " + this.state.token,
          'Content-Type': 'application/json'
        }
      })
      .then((res) => {
        console.log("response:", res)
        this.setState({
          title: "",
          people: "",
          price: "",
          image: "",
          description: "",
          loading: false
        })
        this.props.isSaved();
      })
      .catch((err) => {
        console.log("error", err)
        console.log("error", err.response)
      })
    
    }
    else{
        NotificationManager.error('Please fill the required fields.');
        this.setState({
            error: true,
            loading: false
        })
    }
}

editPackage = () => {
  this.setState({
    loading: true
  })

  if(this.state.title != "" && this.state.description != "" && this.state.people != "" && this.state.price != "" ){
        
    var apiData = new FormData();
    apiData.append("title", this.state.title)
    apiData.append("numberOfPeople", this.state.people)
    apiData.append("price", this.state.price)
    apiData.append("description", this.state.description)
    apiData.append("createdOn", new Date().getTime())
    apiData.append("key", this.props.data.key)
    if(this.state.image){
      apiData.append("image", this.state.image)
      }
  
      console.log("token", this.state.token)

    axios({
      url: serverUrl + "package/update",
      method: "post",
      data: apiData,
      withCredentials: true,
      headers:{
        Authorization: 'Bearer' + " " + this.state.token,
        'Content-Type': 'application/json'
      }
    })
    .then((res) => {
      console.log("response:", res)
      this.setState({
        loading: false
      })

      this.props.isSaved();
    })
    .catch((err) => {
      console.log("error", err)
      console.log("error", err.response)
      this.setState({
        loading: false
      })
    })
  
  }
  else{
      NotificationManager.error("Can't save empty fields.");
      this.setState({
          error: true,
          loading: false
      })
  }
}

  render() {
    return (
      <>
        {/* <Header /> */}
        {/* Page content */}
        <Container className="mt--7" fluid>
        <Card className="shadow">
                        <CardHeader className="border-0">
                          <div className="headRow">
                            
                            <h3 className="mb-0">Package</h3>
                           
                            <div onClick={this.props.back} className="backButton">
                             <h4 className="deleteText">Back</h4>
                            </div>
                            </div>
                        </CardHeader>
                       <CardBody>
                           
                       <div style={{padding: 10}} >
                                <div className="row" style={{marginBottom: 20}}>
    
                                <div style={{display:'flex', alignItems:'center'}} className="col-sm-3 col-md-3">
                                    <h4 style={{paddingLeft:20, }}> Title </h4>
                                </div>
    
                                <div className="col-sm-3 col-md-4">
                                <Input disabled defaultValue={this.props.data ? this.props.data.name : ""} className={this.state.error && this.state.title.length == 0 ? "inputStyle" : ""} type='text' name={"title"}  onChange={this.changeParam}   style={{width: 300}}  placeholder={""}  />
                                </div>

                              
    
                                </div>

                                <div className="row" style={{marginBottom: 20}}>
    
                                    <div style={{display:'flex', alignItems:'center'}} className="col-sm-3 col-md-3">
                                        <h4 style={{paddingLeft:20, }}> Monthly Price </h4>
                                    </div>

                                    <div className="col-sm-3 col-md-4">
                                    <Input disabled defaultValue={this.props.data.prices[1].unit_amount ? (this.props.data.prices[1].unit_amount/100).toFixed(2) : 0} className={this.state.error && this.state.people.length == 0 ? "inputStyle" : ""} type='number' name={"people"}  onChange={this.changeParam}   style={{width: 300}}  placeholder={""}  />
                                    </div>

                                

                                    </div>

                                    <div className="row" style={{marginBottom: 20}}>
    
                                    <div style={{display:'flex', alignItems:'center'}} className="col-sm-3 col-md-3">
                                        <h4 style={{paddingLeft:20, }}>6 Months Price </h4>
                                    </div>

                                    <div className="col-sm-3 col-md-4">
                                    <Input disabled defaultValue={this.props.data ? (this.props.data.prices[0].unit_amount/100).toFixed(2) : ""} className={this.state.error && this.state.price.length == 0 ? "inputStyle" : ""} type='number' name={"price"}  onChange={this.changeParam}   style={{width: 300}}  placeholder={""}  />
                                    </div>

                                

                                    </div>

                                    <div className="row" style={{marginBottom: 20}}>
    
                                        <div style={{display:'flex', alignItems:'center'}} className="col-sm-3 col-md-3">
                                            <h4 style={{paddingLeft:20, }}> Image </h4>
                                        </div>
                                        </div>

                                       
                                        <div className="row" style={{marginBottom: 20}}>
                                        
                                        <div style={{display:'flex', alignItems:'center'}} className="col-sm-3 col-md-3" />

                                        <div className="col-sm-3 col-md-4">
                                        <img src={this.props.data ? this.props.data.images[0] : require('assets/img/brand/noimg.jpg')} style={{width: 200}} />
                                        </div>
                                         </div>
                                        

                                        <div className="row" style={{marginBottom: 20}}>
    
                                            <div style={{display:'flex', alignItems:'center'}} className="col-sm-3 col-md-3">
                                                <h4 style={{paddingLeft:20, }}> Description </h4>
                                            </div>

                                            <div className="col-sm-3 col-md-4">
                                            <Input disabled rows={4} defaultValue={this.props.data ? this.props.data.description : ""} className={this.state.error && this.state.description.length == 0 ? "inputStyle" : ""} type='textarea' name={"description"}  onChange={this.changeParam}   style={{width: 300}}  placeholder={""}  />
                                            </div>

                                        

                                            </div>

                            </div>

                            {/* <div className="row" style={{marginBottom: 20, marginTop:20}}>
                            <div className="col-sm-3 col-md-3" />
                            <div  className="col-sm-3 col-md-4">
                            <Button onClick={this.props.data ? this.editPackage : this.sumbitPackage}
                             style={{width: 300, backgroundColor: '#A52C2C', color: '#fff',
                              borderColor: '#A52C2C', fontWeight:'bold', marginLeft: 5}}>
                                {this.state.loading ?
                                 <Loader
                                 type="Oval"
                                 color="#fff"
                                 height={20}
                                 width={20}
                                //  timeout={3000} //3 secs
                         
                              />
                              :
                                  "Save"
                                }
                            </Button>
                            </div>
                        </div> */}
                            
                       </CardBody>
                       <NotificationContainer/>
                    </Card>
        </Container>
      </>
    );
  }
}

export default AddPackage;
