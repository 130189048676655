/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import firebase from '../../firebase.config';
import './maps.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Badge,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  UncontrolledTooltip,
  Dropdown,
  CustomInput
} from "reactstrap";

// core components
import Header from "components/Headers/Header.js";
// mapTypeId={google.maps.MapTypeId.ROADMAP}
const MapWrapper = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
      defaultOptions={{
        scrollwheel: false,
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [{ color: "#444444" }]
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [{ color: "#f2f2f2" }]
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [{ saturation: -100 }, { lightness: 45 }]
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [{ visibility: "simplified" }]
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [{ color: "#5e72e4" }, { visibility: "on" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
    </GoogleMap>
  ))
);

class ReportPost extends React.Component {
  constructor(props){
    super(props);
    this.state={
      posts:[]
    }
  }
  componentDidMount(){
      console.log("props", this.props.data)
        var post = []
      firebase.firestore().collection("posts").doc(this.props.data.postId).get()
      .then((res) => {
        console.log("post", res.data())

        firebase.firestore().collection("users").doc(res.data().uid).get()
        .then((myRes) => {
          console.log(myRes.data())

          post.push({...this.props.data, post: res.data(), postedBy: myRes.data()})
          this.setState({
              posts: post
          })

        })

      })
  
  }

  deletePost (val) {
    console.log("Delete", val)
    firebase.firestore().collection("posts").doc(val.postId).update({
      isDeleted: true
    })
    .then((done) => {
      console.log("done")
      firebase.firestore().collection("reports").doc(val.reportId).update({
        isDeleted: true
      })
      .then((final) =>{
        this.props.delete()
      })
    })
  }

  render() {
    return (
      <>
        {/* <Header /> */}
        {/* Page content */}
        <Container className="mt--7" fluid>
        <Card className="shadow">
                        <CardHeader className="border-0">
                          <div className="headRow">
                            
                            <h3 className="mb-0">Reports</h3>
                           
                            <div onClick={this.props.delete} className="backButton">
                             <h4 className="deleteText">Back</h4>
                            </div>
                            </div>
                        </CardHeader>
                       <CardBody>
                           {this.state.posts.map((res, index) => {
                               return(
                                   <div key={index}>
                              <div className="row" >
                                  <div className="col-6" >
                                    <div className="row">
                                      <div className="col-3">
                                      <img src={res.user.dp} className="imageStyle" />
                                      </div>
                                      <div className="col-7 box1">
                                      <h3 >{res.user.name}</h3>
                                      </div>
                                      </div>

                                      <div className="row">
                                        <div className="reasonBox">
                                        <h4>Reason: {res.reason}</h4>
                                        <h5>Date: {new Date(res.timestamp).getDate() + "/" + new Date(res.timestamp).getMonth() + "/" + new Date(res.timestamp).getFullYear()}</h5>
                                        <div onClick={() => this.deletePost(res)} className="deleteButton">
                                          <div className="row">
                                        <h4 className="deleteText">Delete</h4>
                                        </div>
                                        </div>
                                        </div>
                                      </div>

                                  </div>

                                  <div className="col-6 sideLine" >
                                  <div className="row">
                                      <div className="col-2">
                                      <img src={res.postedBy.dp ? res.postedBy.dp : require('../../assets/img/brand/person.png')} className="imageStyle1" />
                                      </div>
                                      <div className="col-8 box1">
                                      <h4 >{res.postedBy.name}</h4>
                                      </div>
                                      </div>
                                      
                                      <img src={res.post.imageUrls[0]} className="postImage" />
                                     
                                  </div>

                              </div>

                              </div>
                               );
                           })}
                       </CardBody>
                      
                    </Card>
        </Container>
      </>
    );
  }
}

export default ReportPost;
