/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import firebase from '../../firebase.config';
import './maps.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Badge,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  UncontrolledTooltip,
  Dropdown,
  CustomInput
} from "reactstrap";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from 'axios'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

// core components
import Header from "components/Headers/Header.js";
import { serverUrl } from "./constant";
// mapTypeId={google.maps.MapTypeId.ROADMAP}

var curr;
var date;

var startCurr;
var startDate;
class ViewPayments extends React.Component {
  constructor(props){
    super(props);
    this.state={
      posts:[],
      title: "",
      expiry: "",
      promo: "",
      image: "",
      imageUrl: '',
      description: "",
      discount: "",
      error: false,
      loading: false,
      showDate: '',
      createdOn: '',
      isActive: "",
      startDate: "",
      token : ''
    }
    

  }
  componentDidMount(){
  
    if(this.props.data){
      console.log("edit here", this.props.data)
    //   this.setState({
    //    title: this.props.data.title,
    //    expiry: this.props.data.expiryDate,
    //    promo: this.props.data.promocode,
    //    discount: this.props.data.discount,
    //    image: this.props.data.image,
    //    description: this.props.data.description,
    //    createdOn: this.props.data.createdOn,
    //    isActive: this.props.data.isActive,
    //    startDate: this.props.data.startDate
    //   })
     }

     this.setState({
      token: localStorage.getItem("token")
    })
     
  }

  render() {
    return (
      <>
        {/* <Header /> */}
        {/* Page content */}
        <Container className="mt--7" fluid>
        <Card className="shadow" style={{paddingBottom:20}}>
                        <CardHeader className="border-0">
                          <div className="headBack">
                            
                            <h3 className="mb-0"></h3>
                           
                            <div onClick={this.props.back} className="backButton">
                             <h4 className="deleteText">Back</h4>
                            </div>
                            </div>
                        </CardHeader>
                       <CardBody>

                        {this.props.data.user ?
                       <div style={{padding: 10}} >

                                <div className="row" style={{marginBottom: 20}}>
                                <div style={{display:'flex', alignItems:'center'}} className="col-sm-3 col-md-3">

                                <img className="userImg" src={this.props.data.user.dp ? this.props.data.user.dp : require('../../assets/img/brand/noimg.jpg')} />

                                <div style={{display:'flex', flexDirection:'column', marginLeft:20}} >
                                    <h4 >{this.props.data.user.name} </h4>
                                    <h5 >{this.props.data.user.email} </h5>
                                </div> 

                                </div>      
                                                      
                                </div>

                                {/* <div className="row" style={{marginBottom: 20}}>
                               
                                </div> */}

                            </div>
                            : null }

                           <div className="packRow">
                               <div style={{width:100 + '%', padding:10}} className="row">
                                   <div className="col-2">
                                <img className="packImg" src={this.props.data.package.image ? this.props.data.package.image : require('../../assets/img/brand/noimg.jpg')} />
                                   </div>
                                   <div className="col-5">
                                   <p style={{fontWeight:'bold', fontSize:16}}>{this.props.data.package.title}</p>
                                   <p style={{fontWeight:'bold', fontSize:14}}>Number of Persons {this.props.data.package.numberOfPeople}</p>
                                   <p>{new Date(this.props.data.payment.createdOn).getDate() +"/"+ new Date(this.props.data.payment.createdOn).getMonth() + "/"+ new Date(this.props.data.payment.createdOn).getFullYear() }</p>
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}} className="col-3">
                                    <p style={{fontWeight:'bold', fontSize:16}}>{"Monthly"}</p>
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'center'}} className="col-2">
                                    <p style={{fontWeight:'bold', fontSize:16, color:'red'}}>{"$"+this.props.data.payment.amount}</p>
                                    </div>
                               </div>
                           </div>
                        
                            
                       </CardBody>
                       <NotificationContainer/>
                    </Card>
        </Container>
      </>
    );
  }
}

export default ViewPayments;
