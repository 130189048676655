import React, {useState, Fragment} from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import './stripe.css';
import {
    Button,
   
  } from "reactstrap";
import firebase from '../firebase.config';
import axios from 'axios';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import { serverUrl } from "../views/examples/constant"

const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#a52c2c',
        color: 'gray',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {color: '#fce883'},
        '::placeholder': {color: '#000'},
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee',
      },
    },
  };

export const CheckoutForm = (props) => {

    const [loader, setLoader] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    var stripeData = props.package;
    var error, paymentMethod = stripeData.user.stripePaymentMethodId ;
    if(!paymentMethod){
      ({ error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    }));
    }

    if (!error) {
      console.log("Stripe 23 | token generated!", paymentMethod);
      //send token to backend here


      var data = {
        "uid": firebase.auth().currentUser.uid,
        "packageId": stripeData.key,
        "amount": parseFloat(stripeData.discount).toFixed(2),
        "id": stripeData.user.stripePaymentMethodId ? stripeData.user.stripePaymentMethodId : paymentMethod.id,
        "createdOn": new Date().getTime(),
        "email": stripeData.user.email,
        "name": stripeData.user.name,
        "stripeCustomerId": stripeData.user.stripeCustomerId ? stripeData.user.stripeCustomerId : "",
        "subscriptionType": stripeData.promocode.title,
        "promoCodeId": stripeData.promocode.key,
        "stripePriceId": stripeData.package?.["stripePrice"+stripeData.promocode.title] ?stripeData.package["stripePrice"+stripeData.promocode.title] : "",
        "packageTitle": stripeData.title,
        "stripeProductId": stripeData.stripeProductId ? stripeData.stripeProductId :""
      }

      console.log("data here", data)
     
        axios({
            url: serverUrl + "subscription/payment",
            method: 'post',
            data: data,
            headers:{
                Authorization: 'Bearer' + ' ' + localStorage.getItem('token')
            }
        })
        .then((res) => {
            console.log("response", res)
            setLoader(false);
            alert("Payment successful");
            props.close();
        })
        .catch((err) => {
            console.log("err", err)
            console.log("error", err.response)
            setLoader(false);
        })

    } else {
      console.log(error.message);
      alert(error.message)
      setLoader(false)
    }
  };

  const myButton = (event) => {
      event.preventDefault();
      console.log("hello Ji")
  }

  return (
    <Fragment>
      {props.package.cardInfo ?
        <div className="cardBox" style={{display:"flex",flexDirection:"row"}}>
        <div style={{flex:1}}>
          <div className="row">
        <div className="col-3">
          <h2 style={{color:"#a52c2c", fontWeight:'bold'}}>{props.package.cardInfo.brand.toUpperCase()} </h2>
          
        </div>

        <div className="col-3">
        <span style={{fontSize:14, color:'#000'}}>{"******" + props.package.cardInfo.last4} </span>
        </div>

        

        </div>

        <div className="row">
          <div className="col-3"></div>
          <div className="col-3">
          <span style={{fontSize:14, color:'#000'}}>{props.package.cardInfo.exp_month + "/" + props.package.cardInfo.exp_year} </span>
          </div>
        </div>

        </div>
      <div style={{flex:1,display:'flex',alignItems:'center', justifyContent:'flex-end'}} >
      <button onClick={loader ? null : handleSubmit} className="payButton">
      {loader ?
                    <Loader
                    type="TailSpin"
                    color="#fff"
                    height={20}
                    width={20}
                   //  timeout={3000} //3 secs
            
                 />
                 :
      "PAY"
      }
      </button>
      </div>

      </div>
      :
         <form onSubmit={myButton} style={{ maxWidth: 500 }}>
    <fieldset className="FormGroup">
    <div className="FormRow">
      <CardElement options={CARD_OPTIONS} />
    </div>
  </fieldset>
            <div className="buttonContainer">
             <Button
              className="lang-button modalButton"
              color="primary"
              href="#pablo"
              onClick={loader ? null : handleSubmit}
              size="md"
              >
                  {loader ?
                    <Loader
                    type="TailSpin"
                    color="#fff"
                    height={20}
                    width={20}
                   //  timeout={3000} //3 secs
            
                 />
                 :
                    "PAY"
                  }
             </Button>
             </div>
             </form>
                }
   
    </Fragment>
  );
};