/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import firebase from '../../firebase.config';
import './maps.css';
import AddPackage from './AddPackage'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Badge,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  UncontrolledTooltip,
  Dropdown,
  CustomInput
} from "reactstrap";

// core components
import Header from "components/Headers/Header.js";
import ReportPost from "./ReportPost";
import AddPromoCode from "./AddPromo";
// mapTypeId={google.maps.MapTypeId.ROADMAP}
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import axios from 'axios'
import Modal from 'react-modal';
import { serverUrl } from "./constant";

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      borderRadius          : 15,
      width                 : '25%'
    },
    overlay: {
        backgroundColor: 'rgb(0,0,0,0.4)'
    }
  };

  Modal.setAppElement('#root')

class PromoCode extends React.Component {
  constructor(props){
    super(props);
    this.state={
      reports:[],
      postData: '',
      addPackage: false,
      promoData:[],
      loading: true,
      editData: '',
      deleteData: '',
      deleteModal: false,
      token : ''
    }
  }
  componentDidMount(){
   
    axios({
      url: serverUrl + 'promocode/list',
      method: 'get',
      headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
      }
  })
  .then((res) => {
      console.log("Package Data", res.data)
      this.setState({
          promoData: res.data.result.data,
          loading: false
      })
  })
  .catch((err) => {
      console.log("err", err.response);
  })

    this.setState({
        token: localStorage.getItem("token")
      })

  }

  showPost (data) {
    console.log("selected post", data)
    this.setState({
      post: true,
      postData: data
    })
  }

  addPackage = () => {
      this.setState({
          addPackage: true,
          editData: ''
      })
  }
 
  goBack = () => {
    this.setState({
        addPackage: false
    })
}

selectedCode (data) {
    console.log("data", data)
this.setState({
    editData: data,
    isEdit: true,
    addPackage: true
})
}

savedPackage = () => {
    this.setState({
        addPackage: false,
        editData: ""
    })
}


  render() {
    return (
      <>
        <Header />
        {/* Page content */}
       
        <Container className="mt--7" fluid>

         {this.state.addPackage ?
        <AddPromoCode isSaved={this.savedPackage} data={this.state.editData ? this.state.editData : ""} back={this.goBack} />
        : 
        <Card className="shadow">
                        <CardHeader className="border-0">
                            <h3 className="mb-0">Promo Code</h3>
                        </CardHeader>
                        {this.state.loading ?
                        <div style={{display:'flex', justifyContent:'center', margin:10}}>
                          <Loader
                          type="TailSpin"
                          color="#A52C2C"
                          height={40}
                          width={40}
                         //  timeout={3000} //3 secs
                  
                       />
                       </div>
                       :
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Image</th>
                                    <th scope="col">Promo Code</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">Expiry Date</th>
                                    <th scope="col">Discount</th>  
                                    <th scope="col">View</th>
                                  
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.promoData.map((data, index) => {
                                   if(data.isDeleted != true){
                                    return(
                                        <tr key={index}>
                                    <th scope="row">
                                        <Media className="align-items-center">
                                          
                                            <Media>
                                                <span className="mb-0 text-sm">
                                                <img src={data.image ? data.image : require('assets/img/brand/noimg.jpg')} style={{width:50}} />                       
                                                </span>
                                            </Media>
                                        </Media>
                                    </th>
                                    <td >
                                        <p style={{}} color="" className="badge-dot">   
                                        {data.name}
                                        </p>
                                    </td>

                                    <td>
                                    <p style={{}} color="" className="badge-dot">   
                                        {new Date(data.created).getDate() + "/" + (new Date(data.created).getMonth() + 1) + "/" + new Date(data.created).getFullYear()}
                                        </p>
                                    </td>

                                    <td >
                                        <p style={{}} color="" className="badge-dot">   
                                        {new Date(data.redeem_by).getDate() + "/" + (new Date(data.redeem_by).getMonth() + 1) + "/" + new Date(data.redeem_by).getFullYear()}
                                        </p>
                                    </td>
                                  
                                    <td>
                                    <p style={{}} color="" className="badge-dot">   
                                        {data.amount_off ? data.amount_off : data.percent_off + '%'}
                                        </p>
                                    </td>

                                    <td>
                                        <i style={{fontSize: 20, marginLeft:3, cursor:'pointer'}} onClick={() => this.selectedCode(data)}  className="fas fa-eye"></i>
                                        </td>
                                    
                                </tr>
                                    );
                                    }
                                })}

                            </tbody>
                        </Table>
                        }
                    </Card>
  }
        </Container>
      </>
    );
  }
}

export default PromoCode;
